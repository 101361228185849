import React, {Component} from "react"
import Layout from "../components/layout"
import HeaderContentOffer from "../components/HeaderContentOffer"
import BackgroundHero from "../components/BackgroundHero"
import ServicesOffer from "../components/ServicesOffer"
import Benefits from "../components/Benefits"
import OfferIconServices from "../components/OfferIconServices";
import ToolsIcon from "../assets/svgIcon/tools.svg";
import GlassIcon from "../assets/svgIcon/glass.svg";
import SettingsIcon from "../assets/svgIcon/settings.svg";
import OfferIconInner from "../components/OfferIconInner";
import SEO from "../components/seo"


class Test extends Component {
    render() {
        return (
            <Layout footerDesc="Bardzo ważne jest, aby zapewnić jakość produktu.
            Produkt wysokiej jakości dostarczany do klientów pomaga zdobyć ich zaufanie. CodersBros z siedzibą w Kielcach,
            Polska eleminuje ludzkie błędy we wczesnym etapie wykonania aplikacji">
              <SEO title="Testowanie aplikacji oprogramowania Kielce | CodersBros"
                   description="Dostarczamy profesjonalne rozwiązania w zakresie
                   zapewnienia jakości. Świadczymy pełen zakres wsparcia dla usług testowania oprogramowania."
              />
                <HeaderContentOffer title="Testowanie aplikacji i oprogramowania"
                                    desc="Dostarczamy profesjonalne rozwiązania w zakresie zapewnienia jakości. Świadczymy pełen zakres wsparcia dla usług testowania oprogramowania."/>
                <BackgroundHero img="testing"/>
                <ServicesOffer title="Testowanie w CodersBros"
                               smallTitle="co oferujemy?">
                  <p className="blank paddingInner testingTxt">Testowanie oprogramowania to proces mający na celu określenie dokładności oraz jakości sprawdzanej usługi. Zespół doświadczonych testerów Coders Bros jest w stanie skontrolować czy produkt spełnia określone wymagania, potrzeby i oczekiwania klienta. Nasza odpowiedzialność polega na wskazaniu problemów związanych z błędami i udzieleniu wskazówek, które na celu mają pomóc w ich naprawie. W projekcie, testowanie należy rozpocząć w możliwe jak najwcześniejszej fazie, aby odkryć jak najwięcej usterek, sprawdzić wszystkie wymaganie klienckie, a przez to zmniejszyć koszty oraz czas ewentualnych poprawek. Testowanie jest ważne, ponieważ błędy oprogramowania mogą być kosztowne, a nawet niebezpieczne. Pomagamy w zapewnieniu jakości oraz niezawodności badanych wytworów</p>
                  <OfferIconServices>
                        <GlassIcon/>
                        <OfferIconInner
                            title="Testy manualne"
                            desc="Testy manualne to proces testów gdzie tester manualny wykonuje przypadki testowe ręcznie, bez użycia narzędzi automatyzacji weryfikując czy oprogramowanie działa zgodnie z założeniami. Każda aplikacja musi zostać przetestowana manualnie zanim jej testy będzie można zautomatyzować. Testowanie manualne jest niezbędne i obowiązkowe dla każdego nowo opracowanego oprogramowania, może wykrywać zarówno widoczne jak i ukryte wady oprogramowania, wymaga dużego wysiłku i czasu, ale daje pewność, że oprogramowanie jest bardziej stabilne."
                        />
                    </OfferIconServices>
                    <OfferIconServices>
                        <SettingsIcon/>
                        <OfferIconInner
                            title="Testy wydajnościowe"
                            desc="Testy wydajnościowe to proces określenia szybkości, czasu reakcji i stabilności serwera, baz danych oraz aplikacji pod wyznaczonym obciążeniem. Celem testowania wydajności nie jest wykrywanie defektów funkcjonalnych, lecz znalezienie i eliminacja wąskich gardeł wydajności w oprogramowaniu. Odkrywają co należy poprawić zanim produkt trafi na rynek, wykrywają takie problemy jak wolne działanie aplikacji, podczas gdy kilku lub więcej użytkowników korzysta z systemu. Testy wydajności określą, czy ich oprogramowanie spełnia wymagania dotyczące szybkości, skalowalności i stabilności przy oczekiwanym obciążeniu."
                        />
                    </OfferIconServices>
                    <OfferIconServices>
                        <ToolsIcon/>
                        <OfferIconInner
                            title="Testy automatyczne"
                            desc="To proces gdzie za wykonanie określonych przypadków testowych odpowiada narzędzie do automatyzacji."
                        />
                    </OfferIconServices>
                </ServicesOffer>
                <Benefits title="Korzyści dla Ciebie ze współpracy z CodersBros"
                          smallTitle="dlaczego my?"
                          benefitsArr={["Wykonujemy testy zgodnie z wymaganiami oraz najlepszymi praktykami",
                              "Posiadamy w zespole doświadczonych, certyfikowanych specjalistów",
                              "Wyszukujemy i wskazujemy błędy w aplikacji",
                              "Wnikliwie sprawdzamy jakość oprogramowania",
                              "Jesteśmy elastyczni, dostosowywujemy się do potrzeb klienta",
                              ]}
                />
            </Layout>
        )
    }
}


export default Test
